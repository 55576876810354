<template>
    <div>
        <CRow>
            <CCol lg="6">
                <CCard>
                    <CCardHeader>Bar Chart</CCardHeader>
                    <CCardBody>
                        <CChartBarExample/>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol lg="6">
                <CCard>
                    <CCardHeader>Doughnut Chart</CCardHeader>
                    <CCardBody>
                        <CChartDoughnutExample/>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>

import * as Charts from '../components/charts'
export default {
    name: 'DetailedPanel',
    components: {
        ...Charts
    },
    data() {
        return {
            
        }
    },
}
</script>